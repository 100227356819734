<template>
  <v-container class="pa-6" fluid>

    <backArrow :route="'root'" style="margin-left: 14px;margin-top: 5px;position:initial;"/>
    <v-row class="pt-6" align="start" justify="center">
      <v-col class="px-5 pt-0 pb-0" cols="4">
        <img
          style="height: 115px; width: 100px"
          src="/img/nick/nick-sac.svg"
        />
      </v-col>
      <v-col class="py-7 pt-0 pb-0" cols="8">
        <p class="generic-text pb-0 mb-0">Como posso</p>
        <p class="generic-title" style="font-size: 34px">te ajudar?</p>
        <v-btn v-if="hasConnectionCompany() && !isDisabledMenuMessageria" style="text-transform: initial" small rounded color="primary" @click="clickNick()">
          Falar com Nick
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="start">
      <v-col
        class="pa-2"
        cols="6"
        v-for="menu in getMenuFiltered"
        :key="menu.id"
      >
        <v-card
          class="item-card"
          width="auto"
          height="190"
          @click="clickCard(menu)"
        >
          <v-card-text class="ma-0 pt-10">
            <v-row style="height: 180px">
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <v-img
                    class="mt-2 mx-16"
                    max-height="90"
                    max-width="90"
                    :src="`/img/icons/menu-nick/${ menu.icon }`"
                  />
                </v-row>
                  <v-row align="center" justify="center">
                  <p class="description-menu">{{ menu.title }}</p>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tour
      name="myTour"
      :steps="steps"
      :options="menu.tour.tourOptions"
    />
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import backArrow from '../../components/covid/back-arrow';
import MessageService from "@/services/socialnetwork/MessageService";

export default {
  components: {
    backArrow
  },
  computed: {
    ...mapState(["menu"]),
    getMenuFiltered() {
      let contractedPlans = null;
      if(this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length) {
        contractedPlans = this.$auth.user().beneficiaries;
        return this.filterMenu(contractedPlans);
      } else if (this.$auth.user().dependents && this.$auth.user().dependents.length) {
        contractedPlans = this.$auth.user().dependents;
        return this.filterMenu(contractedPlans);
      } else {
        return this.$auth.getMenuNick().filter(menu => menu.link && menu.link !== 'pharmacies' && menu.link !== 'telemedicine');
      }
    }
  },
  watch: {
    "menu.showtour"() {
      this.controlTour();
    },
  },
  async mounted() {
    this._messageService = new MessageService();
    this.loading(true);
    await this.verifyIsDisabledMenuMessageria();
    this.controlTour(true);
  },
  methods: {
    ...mapMutations(["toggleShowTour", "loading"]),
    filterMenu(contractedPlans) {
      const contractedPlan = contractedPlans.find(contractedPlan => contractedPlan.benefitName === this.title);
      if(contractedPlan) {
        return this.$auth.getMenuNick();
      } else {
        return this.$auth.getMenuNick().filter(menu => menu.link && menu.link !== 'pharmacies' && menu.link !== 'telemedicine');
      }
    },
    async verifyIsDisabledMenuMessageria(){
      await this._messageService.IsDisabledMenuMessageria()
          .then(response => {
            this.isDisabledMenuMessageria = response.data
          })
          .catch(()=>{
            this.isDisabledMenuMessageria = false;
          })
          .finally(() => {
            this.loading(false);
          })
    },
    clickNick(){
      this.$router.push({name: 'message', params: {activeFilter: false}})
    },
    hasConnectionCompany(){
      return this.$auth.user().hasCompanyConnection;
    },
    clickCard(menu){
      menu.type == 'EXTERNAL_URL' ? window.location.href = menu.link : this.$router.push(menu.link)
    },
    openExternalUrl(url) {
      window.location.href = url;
    },
    controlTour(_mounted = false) {
      if (this.menu.showtour) {
        this.$tours["myTour"].start();
      } else {
        this.$tours["myTour"].finish();
      }
    },
  },
  data: () => ({
    title: 'Telemedicina',
    isDisabledMenuMessageria: true,
    items: [],
    colorcard: "#100d38",
    falarcomnick: false,
    steps: [
      {
        target: "#v-step-0",
        header: {
          title: "Olá, João",
        },
        content: `<strong>Vamos fazer um Tour?</strong>!`,
      },
      {
        target: "#v-step-1",
        content: "Encontre as Principais Dúvidas.",
      },
      {
        target: "#v-step-2",
        content: "Veja os seus avisos.",
      },
      {
        target: "#v-step-3",
        content: "Quer abrir um atendimento ou acompanhar? Veja aqui.",
      },
      {
        target: "#v-step-4",
        content: "Tenha suas carteirinhas centralizadas.",
      },
      {
        target: "#v-step-5",
        content: "Acompanhe suas utilizações.",
      },
      {
        target: "#v-step-6",
        content: "Veja Dicas para sua saúde!",
      },
    ],
  }),
};
</script>
<style scoped>
.item-card{
  border-radius: 11px;
  background: rgba(255, 255, 255, .5);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(24px) !important;
}

.description-menu {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #532E88;
}

.icone-umbrella {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.icone-umbrella .umbrella {
  font-size: 150px;
}

.v-card__title {
  word-break: initial;
  padding: initial;
}

.box-padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: #6567a8 3px solid !important;
  border-radius: 4px !important;
}

.box-dicas {
  height: 120px;
}

.box-falar-nick {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #6567a8 3px solid !important;
  border-radius: 4px !important;
}

.titulo-user {
  text-align: center;
  color: #fff;
  font-size: 48px;
}

.subtitulo-user {
  text-align: center;
  color: #fff;
  font-size: 20px;
}

.v-badge {
  display: block !important;
}

.message {
  color: #fff !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background: #6567a8 !important;
}

.v-application .headline {
  font-size: 12px !important;
}
</style>
